import { useState } from 'react';

export const useLocalStorageString = (key: string, initialValue: string | null): [string | null, (value: string | null) => void] => {
    const [storedValue, setStoredValue] = useState(() => {
        const item = typeof window !== 'undefined' && localStorage.getItem(key);

        if (!item) return initialValue;

        return item;
    });

    const setValue = (value: any): void => {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        if (value === null) {
            localStorage.removeItem(key);
            return;
        }

        localStorage.setItem(key, valueToStore);
    };

    return [storedValue, setValue];
}
