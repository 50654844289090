import React, { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocalStorage } from '../../util/useLocalStorage';
import { useLocalStorageString } from '../../util/useLocalStorageString';

interface AuthContextInterface {
    token: string | null;
    isAuthenticated: boolean;

    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext({} as AuthContextInterface);

const { Provider, Consumer } = AuthContext;

const AuthProvider: React.FC = ({ children }) => {
    const [token, setToken] = useLocalStorageString('token', null);
    const [isAuthenticated, setIsAuthenticated] = useState(() => typeof window !== 'undefined' && localStorage.getItem('token') !== null);

    useEffect(() => {
        if (!token) return;

        try {
            const decoded: any = jwt_decode(token);
            if (new Date().getTime() / 1000 > decoded.exp) {
                return logout();
            }
        } catch (err) {
            console.log('Invalid JWT token format.');
            return;
        }
    }, []);

    const login = token => {
        setToken(token);
        setIsAuthenticated(true);
    }

    const logout = () => {
        setToken(null);
        setIsAuthenticated(false);
    }

    return (
        <Provider
            value={{
                token,
                isAuthenticated,

                login,
                logout
            }}
        >
            {children}
        </Provider>
    );
};

export { AuthContext, AuthProvider, Consumer as AuthConsumer };
