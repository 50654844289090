import React from 'react';

const rect = {
    fill: 'url(#g1)',
}

interface Props {
    className?: string;
}

const Logo: React.FC<Props> = ({ className }) => {
    return (
        <svg className={className} viewBox="8 10 440.3 75">
            <defs>
                <linearGradient id="g1" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0" stopColor="#f16e76"/>
                    <stop offset="1" stopColor="#e6548a"/>
                </linearGradient>
                <filter id="b1">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" />
                </filter>
            </defs>
            <g filter="url(#b1)" style={{ opacity: '0.17' }}>
                <rect style={rect} height="42" width="10" x="14.15" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="30.05" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="62.13" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="78.12" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="94.11" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="126.1" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="142.09" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="174.08" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="190.07" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="206.07" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="238.05" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="254.05" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="286.03" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="302.03" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="318.02" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="350.00" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="366.00" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="397.98" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="413.98" y="16" rx="2.25"/>
                <rect style={rect} height="42" width="10" x="429.97" y="16" rx="2.25"/>
            </g>
            <path d="M77.47,49.09a12.15,12.15,0,0,1-.82,4.56,8.28,8.28,0,0,1-2.56,3.4,12.57,12.57,0,0,1-4.56,2.16,26.2,26.2,0,0,1-6.82.76H58.39v9.25a15.27,15.27,0,0,0,.22,2.9,3,3,0,0,0,.91,1.75,3.81,3.81,0,0,0,2,.84,21.63,21.63,0,0,0,3.48.22v1.82H48.09V74.93a14.71,14.71,0,0,0,2.7-.29,3.2,3.2,0,0,0,1.61-.82,3,3,0,0,0,.77-1.63,16.58,16.58,0,0,0,.19-2.73V47.13a17.76,17.76,0,0,0-.17-2.71,3.25,3.25,0,0,0-.67-1.63A2.62,2.62,0,0,0,51.06,42a17.83,17.83,0,0,0-2.49-.31V39.84h15q6.62,0,10.23,2.45A7.77,7.77,0,0,1,77.47,49.09ZM58.39,57.58h3.4q4.8,0,7.12-1.85t2.33-6.06q0-4.08-1.85-5.83T63.62,42.1H59.21a.72.72,0,0,0-.82.81Z"/>
            <path d="M109.91,63.38H97.12c-.55,1.31-1,2.46-1.39,3.47s-.7,1.88-.94,2.61a16.32,16.32,0,0,0-.5,1.87,6.66,6.66,0,0,0-.14,1.25A1.93,1.93,0,0,0,95,74.42a5.93,5.93,0,0,0,2.87.51v1.82H86.24V74.93a12.47,12.47,0,0,0,1.89-.39,4.6,4.6,0,0,0,1.39-.67,4.38,4.38,0,0,0,1.06-1.12A11.7,11.7,0,0,0,91.46,71l12.46-31.2H106l10.88,27q1.11,2.68,1.92,4.29a10.42,10.42,0,0,0,1.58,2.42A3.54,3.54,0,0,0,122,74.64a8.23,8.23,0,0,0,2.13.24v1.87H108.52V74.88a8.71,8.71,0,0,0,3.5-.48,1.64,1.64,0,0,0,1-1.58,6.19,6.19,0,0,0,0-.89,6.74,6.74,0,0,0-.16-.89,10.6,10.6,0,0,0-.34-1.07c-.14-.4-.33-.88-.55-1.42ZM98.08,61H109l-5.32-14.14Z"/>
            <path d="M166.76,61.89a26.56,26.56,0,0,1-.75,6.66,11.56,11.56,0,0,1-2.39,4.79,10.3,10.3,0,0,1-4.29,2.91,18.44,18.44,0,0,1-6.38,1,24.57,24.57,0,0,1-6.18-.7,10.33,10.33,0,0,1-4.41-2.3A9.61,9.61,0,0,1,139.73,70a20.23,20.23,0,0,1-.87-6.38V51c0-2.36-.05-4.19-.16-5.46a4.83,4.83,0,0,0-1-2.83,2.83,2.83,0,0,0-1.55-.84,13,13,0,0,0-2.62-.22V39.84h16.35v1.82a15.4,15.4,0,0,0-2.64.19,2.49,2.49,0,0,0-1.58,1,5.38,5.38,0,0,0-.86,2.76c-.13,1.26-.19,3.08-.19,5.44v13a16.68,16.68,0,0,0,.5,4.33,7.76,7.76,0,0,0,1.65,3.22,7.05,7.05,0,0,0,3,2,13.28,13.28,0,0,0,4.53.67q4.78,0,7.19-2.65t2.39-7.84V52.21a47.09,47.09,0,0,0-.33-6.47,5.61,5.61,0,0,0-1.25-3.12,3.21,3.21,0,0,0-1.68-.79,15.67,15.67,0,0,0-2.49-.17V39.84H172v1.82a11,11,0,0,0-2.52.24A2.57,2.57,0,0,0,168,43a6.81,6.81,0,0,0-1,3.14,58.57,58.57,0,0,0-.24,6.11Z"/>
            <path d="M217.08,76.75h-31V74.93a14.84,14.84,0,0,0,2.71-.29,3.16,3.16,0,0,0,1.6-.82,2.93,2.93,0,0,0,.77-1.63,15.55,15.55,0,0,0,.19-2.73V47.13a18,18,0,0,0-.16-2.71,3.34,3.34,0,0,0-.68-1.63,2.59,2.59,0,0,0-1.46-.82,17.83,17.83,0,0,0-2.49-.31V39.84h14.57v1.82a12.39,12.39,0,0,0-2.47.29,2.68,2.68,0,0,0-1.44.86,3.61,3.61,0,0,0-.67,1.73,20.76,20.76,0,0,0-.16,2.92V68.12a11,11,0,0,0,.4,3.28,3.72,3.72,0,0,0,1.39,2,6.36,6.36,0,0,0,2.61,1,27.54,27.54,0,0,0,4.13.26,28.4,28.4,0,0,0,4.6-.31,8.8,8.8,0,0,0,3.14-1.1,5.74,5.74,0,0,0,2-2.21A15.31,15.31,0,0,0,216,67.31h1.82Z"/>
            <path d="M241.81,67.69a28.34,28.34,0,0,0,.2,3.78,4.68,4.68,0,0,0,.74,2.16,2.57,2.57,0,0,0,1.58,1,14.84,14.84,0,0,0,2.71.29v1.82H231.51V74.93a13,13,0,0,0,2.71-.32,2.86,2.86,0,0,0,1.6-1,4.12,4.12,0,0,0,.77-2,25.84,25.84,0,0,0,.19-3.58V48.57a27.6,27.6,0,0,0-.17-3.34,4.85,4.85,0,0,0-.67-2.08,2.66,2.66,0,0,0-1.46-1.08,10.54,10.54,0,0,0-2.49-.41V39.84h14.57v1.82a8.28,8.28,0,0,0-2.47.39,2.79,2.79,0,0,0-1.44,1.12,5.34,5.34,0,0,0-.67,2.18,31,31,0,0,0-.17,3.55Z"/>
            <path d="M285.09,63.38H272.3c-.55,1.31-1,2.46-1.39,3.47s-.7,1.88-.94,2.61a14.47,14.47,0,0,0-.5,1.87,6.81,6.81,0,0,0-.15,1.25,1.94,1.94,0,0,0,.87,1.84,5.93,5.93,0,0,0,2.87.51v1.82H261.42V74.93a12.47,12.47,0,0,0,1.89-.39,4.6,4.6,0,0,0,1.39-.67,4.18,4.18,0,0,0,1.05-1.12,10.77,10.77,0,0,0,.89-1.71l12.46-31.2h2.06l10.88,27q1.11,2.68,1.92,4.29a10.42,10.42,0,0,0,1.58,2.42,3.51,3.51,0,0,0,1.65,1.06,8.31,8.31,0,0,0,2.14.24v1.87H283.7V74.88a8.71,8.71,0,0,0,3.5-.48,1.64,1.64,0,0,0,1-1.58,8.08,8.08,0,0,0-.05-.89,6.88,6.88,0,0,0-.17-.89c-.08-.31-.19-.67-.33-1.07s-.33-.88-.55-1.42ZM273.25,61h10.93l-5.32-14.14Z"/>
            <path d="M345.39,77.23l-22.77-30h-.19V67.69a31.19,31.19,0,0,0,.17,3.78,5,5,0,0,0,.67,2.16,2.33,2.33,0,0,0,1.44,1,12.19,12.19,0,0,0,2.46.29v1.82H314.28V74.93a13,13,0,0,0,2.71-.32,2.83,2.83,0,0,0,1.6-1,4.12,4.12,0,0,0,.77-2,25.84,25.84,0,0,0,.19-3.58V48.57a27.88,27.88,0,0,0-.16-3.34,5,5,0,0,0-.68-2.08,2.66,2.66,0,0,0-1.46-1.08,10.54,10.54,0,0,0-2.49-.41V39.84h9.49L345.2,67.78h.19V48.9a33.76,33.76,0,0,0-.17-3.76,5.15,5.15,0,0,0-.67-2.18,2.39,2.39,0,0,0-1.44-1,12.3,12.3,0,0,0-2.47-.29V39.84h12.89v1.82a13.67,13.67,0,0,0-2.7.31,2.92,2.92,0,0,0-1.61,1,4.26,4.26,0,0,0-.77,2.06,27.55,27.55,0,0,0-.19,3.55V77.23Z"/>
            <path d="M385.7,39.36a20.63,20.63,0,0,1,7.86,1.49,19.9,19.9,0,0,1,6.32,4.07A19,19,0,0,1,404.1,51a18.69,18.69,0,0,1,1.53,7.57,17.29,17.29,0,0,1-1.55,7.29,18.88,18.88,0,0,1-4.24,5.92,20.14,20.14,0,0,1-6.33,4,21.65,21.65,0,0,1-15.63,0,20.2,20.2,0,0,1-6.32-4,18.75,18.75,0,0,1-4.25-5.92,17.44,17.44,0,0,1-1.55-7.29A18.69,18.69,0,0,1,367.29,51a19,19,0,0,1,4.22-6.09,19.84,19.84,0,0,1,6.33-4.07A20.56,20.56,0,0,1,385.7,39.36Zm0,35.23a14,14,0,0,0,5.67-1.1,12.21,12.21,0,0,0,4.32-3.16,14.29,14.29,0,0,0,2.76-5,21.58,21.58,0,0,0,.95-6.59,23.65,23.65,0,0,0-.95-6.93,15.59,15.59,0,0,0-2.74-5.27,12,12,0,0,0-4.31-3.36,14.49,14.49,0,0,0-11.41,0,12,12,0,0,0-4.31,3.36A15.57,15.57,0,0,0,373,51.8a23.31,23.31,0,0,0-1,6.93,21.27,21.27,0,0,0,1,6.59,14.56,14.56,0,0,0,2.73,5A11.91,11.91,0,0,0,380,73.49,14.32,14.32,0,0,0,385.7,74.59Z"/>
        </svg>
    );
};

export default Logo;
